<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <template>
      <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Detalle Cliente' }]" />
      <v-layout row wrap>
        <v-flex md12 sm12 xs12>
          <v-card>
            <v-card-title primary-title>
              <span class="success--text font-weight-bold headline">Editar Cliente</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-form
                ref="form"
                v-model="validForm"
                lazy-validation
                @submit.prevent="submitEditCliente"
              >
                <v-container fluid grid-list-lg>
                  <v-layout row wrap>
                    <v-flex md12 sm12 xs12>
                      <v-text-field
                        v-model="form.email"
                        :disabled="processingForm"
                        label="Email"
                        outline
                        :rules="rules.email"
                        :error="!!formErrors.email"
                        :error-messages="formErrors.email"
                        @keyup="
                          () => {
                            formErrors.email = undefined;
                            delete formErrors.email;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md6 sm12 xs12>
                      <v-text-field
                        v-model="form.nombre"
                        :disabled="processingForm"
                        label="Nombre"
                        outline
                        :rules="rules.nombre"
                        :error="!!formErrors.nombre"
                        :error-messages="formErrors.nombre"
                        @keyup="
                          () => {
                            formErrors.nombre = undefined;
                            delete formErrors.nombre;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md6 sm12 xs12>
                      <v-text-field
                        v-model="form.apellidos"
                        :disabled="processingForm"
                        label="Apellidos"
                        outline
                        :rules="rules.apellidos"
                        :error="!!formErrors.apellidos"
                        :error-messages="formErrors.apellidos"
                        @keyup="
                          () => {
                            formErrors.apellidos = undefined;
                            delete formErrors.apellidos;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md4 sm12 xs12>
                      <v-text-field
                        v-model="form.telefono"
                        :disabled="processingForm"
                        label="Telefono"
                        outline
                        :rules="rules.telefono"
                        :error="!!formErrors.telefono"
                        :error-messages="formErrors.telefono"
                        @keyup="
                          () => {
                            formErrors.telefono = undefined;
                            delete formErrors.telefono;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md4 sm12 xs12>
                      <v-text-field
                        v-model="form.celular"
                        :disabled="processingForm"
                        label="Celular"
                        outline
                        :rules="rules.celular"
                        :error="!!formErrors.celular"
                        :error-messages="formErrors.celular"
                        @keyup="
                          () => {
                            formErrors.celular = undefined;
                            delete formErrors.celular;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md4 sm12 xs12>
                      <v-text-field
                        v-model="form.rut"
                        :disabled="processingForm"
                        label="Rut"
                        outline
                        :rules="rules.rut"
                        :error="!!formErrors.rut"
                        :error-messages="formErrors.rut"
                        @keyup="
                          () => {
                            formErrors.rut = undefined;
                            delete formErrors.rut;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md4 sm12 xs12>
                      <v-text-field
                        v-model="form.direccion"
                        :disabled="processingForm"
                        label="Direccion"
                        outline
                        :rules="rules.direccion"
                        :error="!!formErrors.direccion"
                        :error-messages="formErrors.direccion"
                        @keyup="
                          () => {
                            formErrors.direccion = undefined;
                            delete formErrors.direccion;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md4 sm12 xs12>
                      <v-text-field
                        v-model="form.ubicacion"
                        :disabled="processingForm"
                        label="Información adicional"
                        outline
                        :rules="rules.ubicacion"
                        :error="!!formErrors.ubicacion"
                        :error-messages="formErrors.ubicacion"
                        @keyup="
                          () => {
                            formErrors.ubicacion = undefined;
                            delete formErrors.ubicacion;
                          }
                        "
                      />
                    </v-flex>
                    <v-flex md4 sm12 xs12>
                      <v-autocomplete
                        v-model="form.ciudad_id"
                        :items="ciudades"
                        dense
                        outline
                        clearable
                        small-chips
                        label="Ciudad"
                        item-text="nombre"
                        item-value="id"
                        :rules="rules.ciudad_id"
                        :disabled="processingForm"
                        :error="!!formErrors.ciudad_id"
                        :error-messages="formErrors.ciudad_id"
                        @change="
                          () => {
                            formErrors.ciudad_id = undefined;
                            delete formErrors.ciudad_id;
                          }
                        "
                      />
                    </v-flex>                    
                    <v-flex md12 sm12 xs12>
                      <v-text-field
                        v-model="form.nota"
                        :disabled="processingForm"
                        label="Nota"
                        outline
                        :rules="rules.nota"
                        :error="!!formErrors.nota"
                        :error-messages="formErrors.nota"
                        @keyup="
                          () => {
                            formErrors.nota = undefined;
                            delete formErrors.nota;
                          }
                        "
                      />
                    </v-flex>                    
                  </v-layout>
                </v-container>
                <v-divider class="mb-3" />
                <div class="text-xs-center pb-3">
                  <v-btn
                    type="submit"
                    color="success"
                    :disabled="!validForm || processingForm"
                    :loading="processingForm"
                    >Guardar</v-btn
                  >
                  <v-btn color="error" @click="$router.push({ name: 'ListCliente' })">
                    Volver</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
export default {
  metaInfo() {
    return { title: "Nueva Clientes" };
  },

  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
  },

  data() {
    return {
      formErrors: {},
      form: {
        nombre: "",
        email: "",
        telefono: "",
        celular: "",
        nota: "",
        ciudad_id: "",
        direccion: "",
        ubicacion: "",
        apellidos: "",
      },
      ciudades: [],
      validForm: true,
      processingForm: false,

      rules: {
        nombre: [(v) => !!v || "El nombre es requerido"],
        email: [(v) => !!v || "El email es requerido"],
      },
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
  },
  async mounted() {
    this.getData();
    const res = await this.getCliente({ clienteId: this.$route.params.id });
    this.setForm(res.data.data);
  },
  methods: {
    ...mapActions({
      getCliente: "clientes/getCliente",
      updateCliente: "clientes/updateCliente",
    }),
    getData() {
      let url = `${HOST}/ciudades`;
      axios.get(url).then((response) => {
        this.ciudades = response.data.data;
      });
    },
    setForm(cliente) {
      this.form.rut = cliente.rut;
      this.form.nombre = cliente.nombre;
      this.form.email = cliente.email;
      this.form.telefono = cliente.telefono;
      this.form.celular = cliente.celular;
      this.form.nota = cliente.nota;
      this.form.direccion = cliente.direccion.direccion;
      this.form.ubicacion = cliente.direccion.ubicacion;
      this.form.ciudad_id = cliente.direccion.ciudad_id;
      this.form.apellidos = cliente.apellidos;
    },
    submitEditCliente() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.updateCliente({ clienteId: this.$route.params.id, data: this.form })
        .then((response) => {
          this.processingForm = false;
          this.$router.push({ name: "ListCliente" });
        })
        .catch((error) => {
          this.processingForm = false;
          this.formErrors = error.response.data.errors || {};
        });
    },
  },
};
</script>
